import { useUserData } from '../functions/TerminalFunc';
import Statement from '../functions/TerminalStatement';

import './_Terminal.scss';

function Terminal() {
  const { userData, typing } = useUserData();

  // Define an array of objects representing the data to display
  const userInfo = userData ? [
    { label: `${userData.name}.currentLocation`, content: <span className="info">{userData.contactInfo.currentLocation }</span> },
    {
      label: `${userData.name}.contactInfo`,
      content: [
        { url: `mailto:${userData.contactInfo.email}`, text: userData.contactInfo.email },
        { url: userData.contactInfo.linkedIn, text: 'linkedin' },
        { url: userData.contactInfo.github, text: 'github' }
      ],
      isLink: true
    },
    { label: `${userData.name}.resume`, content: [{ url: userData.resumeLink, text: '/xin_resume.pdf' }], isLink: true },
    { label: `${userData.name}.interests`, content: <span className="info">{userData.interests.hobbies.join(', ')}</span>, isArray: true },
    { label: `${userData.name}.education`, content: <span className="info">{userData.education}</span> },
    { label: `${userData.name}.skills`, content: <span className="info">{userData.skills.join(', ')}</span>, isArray: true },
  ] : [];

  return (
    <div className="terminal">
      <div className="terminal-header">
        <div className="header-button red"></div>
        <div className="header-button yellow"></div>
        <div className="header-button green"></div>
      </div>
      {/* Terminal UI code */}
      <div className="terminal-window">
        {userInfo.map((info, index) => (
          <Statement key={index} label={info.label} content={info.content} isLink={info.isLink} isArray={info.isArray} />
        ))}
        {/* Handle the typing indicator separately */}
        <div className="statement">
          <div className="input-statement">{userData ? `${userData.consoleText}` : `Loading...`}<span>{typing ? '|': ''}</span></div>
        </div>
      </div>
    </div>
  );
}

export default Terminal;