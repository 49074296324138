import React from 'react';
import Typewriter from './Typewriter';
import { Scrollchor } from 'react-scrollchor';
import './_Hero.scss';

class Hero extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="hero-container">
        <Typewriter className="hero-title" text="Dominic Andutan" />
        <div className="hero-description">
        I'm an Information Technology student at Western Institute of Technology, 
        passionate about reverse engineering.<br />Check out my{' '}
          <Scrollchor to="#projects">side-projects</Scrollchor> below.
        </div>
      </div>
    );
  }
}

export default Hero;