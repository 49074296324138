import todo from '../images/todo.png';
import portfolio from '../images/portfolio.png';

export default [
  {
    title: 'Todo App',
    description:
      'A simple todo app that allows users to add, edit, and delete tasks. The app is built using MERN stack.',
    skills: ['React', 'Node.js', 'Express', 'MongoDB'],
    image: todo,
    links: {
      github: 'https://github.com/Xin1337/todosapp',
      preview: 'https://todosapp-g41e.onrender.com/',
    }
  },
  {
    title: 'Portfolio',
    description:
      'A personal portfolio website built using React and Express. The website is responsive and mobile-friendly.',
    skills: ['React', 'Express', "Node.js"],
    image: portfolio,
    links: {
      github: 'https://github.com/Xin1337/react-express',
      preview: 'https://nicz.tech/',
    },
  }
];