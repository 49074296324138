import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Terminal from './components/Terminal';
import Projects from './components/Projects';
import Footer from './components/Footer';
import projects from './content/projects';
import Contact from './components/Contact';

import './scss/index.scss';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <Terminal />
      <h2 id="projects" className="section-title">
        Projects
      </h2>
      <Projects projects={projects} />
      <div className="background" />
        <h2 id="contact" className="section-title">
          Contact me!
      </h2>
      <Contact />
      <Footer />
    </div>
  );
}

export default App;