import React from 'react';
import './_Contact.scss';

class Contact extends React.PureComponent {
  render() {
    return (
      <div className="contact-links">
        <span>
          <a rel="noopener" target="_blank" href="mailto:xin@tfwno.gf">
            xin@tfwno.gf
          </a>
        </span>
        <span>
          <a
            rel="noopener"
            target="_blank"
            href="https://www.linkedin.com/in/nicplaceholderhehe"
          >
            LinkedIn
          </a>
        </span>
        <span>
          <a rel="noopener" target="_blank" href="https://github.com/Xin1337">
            github
          </a>
        </span>
      </div>
    );
  }
}

export default Contact;